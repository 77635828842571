import { css } from 'lit';
import { ConfigDMS } from '@/config/ConfigDMS';
import { FRAME_FIELD_NAMES } from '@/constants';
import { CustomMessageDto, DMSSignType, SignDto } from '@typings/api';
import { isValidNumber } from './utils';

export function signHasMessage(sign: SignDto) {
	const typeConfig = ConfigDMS.Map.icons[sign.type];

	if (sign.type === DMSSignType.VSL) {
		return (
			typeConfig &&
			sign.status === 'ACTIVE' &&
			sign.displayedMessageDto?.frame1?.text !== undefined &&
			sign.displayedMessageDto?.frame1?.text !== null &&
			sign.displayedMessageDto?.frame1?.text !== '' &&
			sign.displayedMessageDto?.frame1?.text !== '[nl]' &&
			sign.displayedMessageDto?.frame1?.text !== '[jp3][jl3]' &&
			sign.displayedMessageDto?.frame1?.text !== '[jp3][jl3][nl]'
		);
	}
	return (
		typeConfig &&
		sign.status === 'ACTIVE' &&
		sign.messageCount !== undefined &&
		sign.messageCount > 0
	);
}

export function signHasNoMessage(sign: SignDto) {
	const typeConfig = ConfigDMS.Map.icons[sign.type];
	if (sign.type === DMSSignType.VSL) {
		return (
			typeConfig &&
			sign.status === 'ACTIVE' &&
			(sign.displayedMessageDto?.frame1?.text === undefined ||
				sign.displayedMessageDto?.frame1?.text === null ||
				sign.displayedMessageDto?.frame1?.text === '' ||
				sign.displayedMessageDto?.frame1?.text === '[jp3][jl3]' ||
				sign.displayedMessageDto?.frame1?.text === '[jp3][jl3][nl]' ||
				sign.displayedMessageDto?.frame1?.text === '[nl]')
		);
	}
	return typeConfig && sign.status === 'ACTIVE' && sign.messageCount === 0;
}

export const messageHasGraphicFrame = (message: CustomMessageDto): boolean => {
	return FRAME_FIELD_NAMES.some((field) => isValidNumber(message[field]?.graphicId));
};

export const messageIsSupportedBySign = (message?: CustomMessageDto, sign?: SignDto): boolean => {
	if (!sign || !message) {
		return false;
	}

	switch (sign.type) {
		case DMSSignType.DMS:
			if (sign.fullMatrix) {
				return !message.shortMessage ?? false;
			}
			if (!sign.fullMatrix) {
				return !messageHasGraphicFrame(message) && !message.shortMessage;
			}
			return true;
		case DMSSignType.PDMS:
			if (sign.fullMatrix) {
				return messageHasGraphicFrame(message) || (message.shortMessage ?? false);
			}
			if (!sign.fullMatrix) {
				return !messageHasGraphicFrame(message) && (message.shortMessage ?? false);
			}
			return true;
		default:
			return true;
	}
};
