import { css } from 'lit';
/* eslint-disable max-lines */
import get from 'lodash-es/get';
import set from 'lodash-es/set';
import isEqual from 'lodash-es/isEqual';
import { Action } from 'redux';
import HTTPMethod from 'http-method-enum';
import { isValidNumber } from '@/utils/utils';
import APIRequest, { APIError, APIRequestReturn, getAPIHeaders } from '../APIRequest';
import { ThunkActionRoot } from '../redux-store';
import ConfigCARSx, { APIConfig, DebuggingConfig } from '../../config/ConfigCARSx';
import { navigate } from '../redux-routing';
import { addItem, removeItem } from '../redux-loaderAnim';
import {
	HighwayHelperDto,
	HighwayHelperFieldDto,
	HighwayHelperTimelineDto,
	HighwayHelperUserDto,
	HighwayHelperUserDtoBase,
} from '../../../typings/api';
import { APIErrorJSON, Banner, NotificationErrorType } from '../../../typings/shared-types';
import { ConfigHHApi } from '../../config/ConfigHH';
import { AppSection } from '../../constants';
import { showMainBanner } from '../redux-ui';
import { isAPIErrorJSON } from '../../utils/type-guards';
//	STATE

export type HHState = {
	banners?: {
		[key in NotificationErrorType]: Banner[];
	};
	apiError?: APIError;
	fields?: HighwayHelperFieldDto;
	helpers?: HighwayHelperDto[];
	sspUsers?: HighwayHelperUserDto[];
	fieldsLastPolled?: number;
	helpersLastPolled?: number;
	unsavedDraftPatrol: boolean;
	draftPatrol?: HighwayHelperDto;
	helperTimeline?: HighwayHelperTimelineDto;
	readOnlyMode?: boolean;
	loading: boolean;
};

export const HH_STATE_INITIAL: HHState = {
	banners: { ERROR: [], SUCCESS: [], WARNING: [] },
	apiError: undefined,
	fields: undefined,
	helpers: undefined,
	sspUsers: undefined,
	fieldsLastPolled: undefined,
	helpersLastPolled: undefined,
	unsavedDraftPatrol: false,
	draftPatrol: undefined,
	helperTimeline: undefined,
	readOnlyMode: false,
	loading: false,
};

//	ACTION TYPES
export enum HHActionType {
	SET_ERROR_STATE = 'SET_ERROR_STATE',
	GET_FIELDS = 'GET_FIELDS',
	SET_FIELDS = 'SET_FIELDS',
	GET_HIGHWAY_HELPERS = 'GET_HIGHWAY_HELPERS',
	SET_HIGHWAY_HELPERS = 'SET_HIGHWAY_HELPERS',
	SET_HH_TABLE_SEARCH = 'SET_HH_TABLE_SEARCH',
	FILTER_TYPES = 'FILTER_TYPES',
	SET_HH_TABLE_COLUMNS = 'SET_HH_TABLE_COLUMNS',
	SET_HH_DRAFT_PATROL_PROP = 'SET_HH_DRAFT_PATROL_PROP',
	UPDATE_HH_PATROL = 'UPDATE_HH_PATROL',
	HH_PATROL_UPDATED = 'HH_PATROL_UPDATED',
	SET_HH_DRAFT_PATROL = 'SET_HH_DRAFT_PATROL',
	SET_HH_UNSAVED_DRAFT_PATROL = 'SET_HH_UNSAVED_DRAFT_PATROL',
	SHOW_NOTIFICATION_BANNER = 'SHOW_NOTIFICATION_BANNER',
	HIDE_NOTIFICATION_BANNER = 'HIDE_NOTIFICATION_BANNER',

	GET_HH_TIMELINE = 'GET_HH_TIMELINE',
	SET_HH_TIMELINE = 'SET_HH_TIMELINE',

	GET_HIGHWAY_HELPER = 'GET_HIGHWAY_HELPER',

	ADD_NOTE_TO_HH_TIMELINE = 'ADD_NOTE_TO_HH_TIMELINE',

	SET_HH_READ_ONLY_MODE = 'SET_HH_READ_ONLY_MODE',

	SET_HH_LOADING = 'SET_HH_LOADING',

	SET_SSP_USERS = 'SET_SSP_USERS',

	SET_SSP_USER = 'SET_SSP_USER',

	REMOVE_SSP_USER = 'REMOVE_SSP_USER',
}

interface SetErrorState extends Action<typeof HHActionType.SET_ERROR_STATE> {
	apiError?: APIError;
}

type GetFields = Action<HHActionType.GET_FIELDS>;

type GetHighwayHelpers = Action<HHActionType.GET_HIGHWAY_HELPERS>;

interface SetFields extends Action<HHActionType.SET_FIELDS> {
	fields: HighwayHelperFieldDto;
}

interface SetHighwayHelpers extends Action<HHActionType.SET_HIGHWAY_HELPERS> {
	helpers: HighwayHelperDto[];
}

interface SetHHTableSearch extends Action<typeof HHActionType.SET_HH_TABLE_SEARCH> {
	search: string;
}

interface SetHHTableColumns extends Action<typeof HHActionType.SET_HH_TABLE_COLUMNS> {
	columns: string[];
}

interface SetHHDraftPatrol extends Action<typeof HHActionType.SET_HH_DRAFT_PATROL> {
	patrol: HighwayHelperDto;
}

interface SetHHDraftPatrolProp extends Action<typeof HHActionType.SET_HH_DRAFT_PATROL_PROP> {
	path: string;
	value: unknown;
}

interface UpdateHHPatrol extends Action<typeof HHActionType.UPDATE_HH_PATROL> {
	draftPatrol: HighwayHelperDto;
}

interface SetHHUnsavedDraftPatrol extends Action<typeof HHActionType.SET_HH_UNSAVED_DRAFT_PATROL> {
	isUnsaved: boolean;
}

type GetHHTimeline = Action<typeof HHActionType.GET_HH_TIMELINE>;

interface SetHHTimeline extends Action<typeof HHActionType.SET_HH_TIMELINE> {
	timeline: HHState['helperTimeline'];
}

interface GetHighwayHelper extends Action<typeof HHActionType.GET_HIGHWAY_HELPER> {
	hhId: number;
}

interface AddNoteToHHTimeline extends Action<typeof HHActionType.ADD_NOTE_TO_HH_TIMELINE> {
	patrolId: number;
	note: string;
}

interface SetHHReadOnly extends Action<typeof HHActionType.SET_HH_READ_ONLY_MODE> {
	readOnlyMode: boolean;
}

interface SetHHLoading extends Action<typeof HHActionType.SET_HH_LOADING> {
	loading: boolean;
}

interface SetSSPUser extends Action<typeof HHActionType.SET_SSP_USER> {
	sspUser: HighwayHelperUserDto;
}

interface RemoveSSPUser extends Action<typeof HHActionType.REMOVE_SSP_USER> {
	id: number;
}

interface SetSSPUsers extends Action<typeof HHActionType.SET_SSP_USERS> {
	sspUsers: HighwayHelperUserDto[];
}

export type HHAction =
	| SetErrorState
	| GetFields
	| SetFields
	| GetHighwayHelpers
	| SetHighwayHelpers
	| SetHHTableSearch
	| SetHHTableColumns
	| SetHHDraftPatrol
	| SetHHDraftPatrolProp
	| UpdateHHPatrol
	| SetHHUnsavedDraftPatrol
	| GetHHTimeline
	| SetHHTimeline
	| GetHighwayHelper
	| AddNoteToHHTimeline
	| SetHHReadOnly
	| SetHHLoading
	| SetSSPUser
	| SetSSPUsers
	| RemoveSSPUser;

//	ACTIONS

export const setHHTableSearch = (search: SetHHTableSearch['search']): SetHHTableSearch => ({
	type: HHActionType.SET_HH_TABLE_SEARCH,
	search,
});

export const setHHTableColumns = (columns: SetHHTableColumns['columns']): SetHHTableColumns => ({
	type: HHActionType.SET_HH_TABLE_COLUMNS,
	columns,
});

export const setHHDraftPatrolProp =
	(path: string, value: unknown): ThunkActionRoot<void> =>
	(dispatch): void => {
		dispatch({
			type: HHActionType.SET_HH_DRAFT_PATROL_PROP,
			path,
			value,
		});
	};

export const setHHDraftPatrol =
	(patrol?: HighwayHelperDto): ThunkActionRoot<void> =>
	(dispatch): void => {
		dispatch({
			type: HHActionType.SET_HH_DRAFT_PATROL,
			patrol,
		});
	};

export const setHHUnsavedDraftPatrol =
	(isUnsaved: boolean): ThunkActionRoot<void> =>
	(dispatch): void => {
		dispatch({
			type: HHActionType.SET_HH_UNSAVED_DRAFT_PATROL,
			isUnsaved,
		});
	};

export const setHHTimeline = (timeline: SetHHTimeline['timeline']): SetHHTimeline => ({
	type: HHActionType.SET_HH_TIMELINE,
	timeline,
});

export const setHHLoading = (loading: boolean): SetHHLoading => ({
	type: HHActionType.SET_HH_LOADING,
	loading,
});

export const getHighwayHelper =
	(hhId: number): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch, getState): Promise<APIRequestReturn> => {
		dispatch({
			type: HHActionType.GET_HIGHWAY_HELPER,
			hhId,
		});
		const apiRequestReturn = await APIRequest(
			new Request(new URL(ConfigHHApi.HighwayHelperById(hhId), APIConfig.endpointURLBase).href, {
				method: HTTPMethod.GET,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
		);

		try {
			const hh = (await apiRequestReturn.response?.clone().json()) as HighwayHelperDto;
			const { draftPatrol } = getState().hh;
			if (!draftPatrol?.id) {
				dispatch(setHHDraftPatrol(hh));
			}
		} catch (error) {
			apiRequestReturn.apiError = APIError.ResponseUnparseable;
			if (DebuggingConfig.showConsoleLogs) {
				console.error(`error parsing event #${hhId}:`, error);
			}
		}
		return apiRequestReturn;
	};

export const refreshHH =
	(hhId: number): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch, getState): Promise<APIRequestReturn> => {
		//	TODO: refactor the polling logic a bit to simplify sequence of requests
		dispatch({
			type: HHActionType.GET_HH_TIMELINE,
			hhId,
		});
		const url = new URL(ConfigHHApi.TimelineById(hhId), APIConfig.endpointURLBase);
		url.searchParams.set('currentPatrolOnly', 'true');
		const apiRequestReturn: APIRequestReturn = await APIRequest(
			new Request(url.href, {
				method: HTTPMethod.GET,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
		);

		let eventShouldPoll = true;
		let invalidTimeline = false;
		let helperTimeline: HHState['helperTimeline'] | APIErrorJSON;

		//	try parsing response

		try {
			helperTimeline = (await apiRequestReturn.response?.clone().json()) as
				| HHState['helperTimeline']
				| APIErrorJSON;
		} catch (error) {
			apiRequestReturn.apiError = APIError.ResponseUnparseable;
			if (DebuggingConfig.showConsoleLogs) {
				console.error(`error parsing event timeline for event #${hhId}:`, error);
			}
			invalidTimeline = true;
		}

		//	is response useable?

		if (helperTimeline === undefined || isAPIErrorJSON(helperTimeline)) {
			apiRequestReturn.apiError = APIError.ServerError;
			if (DebuggingConfig.showConsoleLogs) {
				console.error(`server error fetching timeline for event #${hhId}:`, helperTimeline);
			}
			invalidTimeline = true;
		} else if (helperTimeline?.timeline?.entries?.length === 0) {
			apiRequestReturn.apiError = APIError.ResponseEmpty;
			if (DebuggingConfig.showConsoleLogs) {
				console.warn(`warning: timeline endpoint returned an empty result for event #${hhId}`);
			}
			invalidTimeline = true;
		}

		//	are there new timeline entries?

		const { helperTimeline: previoushelperTimeline } = getState().hh;
		const helperTimelineAsTimeline = helperTimeline as HHState['helperTimeline'];

		if (
			helperTimelineAsTimeline &&
			previoushelperTimeline &&
			previoushelperTimeline.id === helperTimelineAsTimeline.id &&
			previoushelperTimeline.timeline?.entries?.length &&
			helperTimelineAsTimeline.timeline?.entries?.length &&
			previoushelperTimeline.timeline.entries[0].timestamp ===
				helperTimelineAsTimeline.timeline.entries[0].timestamp
		) {
			eventShouldPoll = false; //	most recent timestamp is the same, so don't bother polling
		}

		//	set timeline data accordingly
		if (invalidTimeline) {
			dispatch(setHHTimeline(undefined));
		} else {
			dispatch(setHHTimeline(helperTimeline as HHState['helperTimeline'])); //	update state with new timeline data
		}

		//	and fetch updated event record if warranted

		const { unsavedDraftPatrol } = getState().hh;

		if (eventShouldPoll && !unsavedDraftPatrol) {
			await dispatch(getHighwayHelper(hhId));
		}

		return apiRequestReturn;
	};

export const addNoteToHHTimeline =
	(note: string, eventId: number): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		dispatch({ type: HHActionType.ADD_NOTE_TO_HH_TIMELINE });

		const newTimelineNote = { notes: note };

		const apiRequestReturn: APIRequestReturn = await APIRequest(
			new Request(new URL(ConfigHHApi.TimelineById(eventId), APIConfig.endpointURLBase).href, {
				method: HTTPMethod.POST,
				headers: new Headers({
					...getAPIHeaders(),
				}),
				body: JSON.stringify(newTimelineNote),
			}),
		);

		return apiRequestReturn;
	};

export const getHHFields =
	(): ThunkActionRoot<Promise<void>> =>
	async (dispatch): Promise<void> => {
		dispatch({ type: HHActionType.GET_FIELDS });

		const url = new URL(ConfigHHApi.FieldsEndpoint, APIConfig.endpointURLBase);
		const apiRequestReturn = await APIRequest(
			new Request(url.href, {
				method: HTTPMethod.GET,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
			ConfigHHApi.endpointTimeoutMs,
		);

		if (!apiRequestReturn?.response) {
			dispatch({ type: HHActionType.SET_ERROR_STATE, apiError: APIError.FetchFailed });
		} else if (apiRequestReturn.response.status === 401) {
			void dispatch(navigate(ConfigCARSx.Pages[AppSection.LOGIN].route));
		} else if (apiRequestReturn.response.ok === true) {
			try {
				const fields = (await apiRequestReturn.response.json()) as HighwayHelperFieldDto;
				dispatch({ type: HHActionType.SET_FIELDS, fields });
			} catch (error) {
				dispatch({ type: HHActionType.SET_ERROR_STATE, apiError: APIError.ResponseUnparseable });
			}
		} else {
			dispatch({ type: HHActionType.SET_ERROR_STATE, apiError: APIError.FetchFailed });
		}
	};

export const getHighwayHelpers =
	(): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		dispatch({ type: HHActionType.GET_HIGHWAY_HELPERS });

		const url = new URL(ConfigHHApi.Endpoint, APIConfig.endpointURLBase);
		const apiRequestReturn = await APIRequest(
			new Request(url.href, {
				method: HTTPMethod.GET,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
			ConfigHHApi.endpointTimeoutMs,
		);

		if (!apiRequestReturn?.response) {
			dispatch({ type: HHActionType.SET_ERROR_STATE, apiError: APIError.FetchFailed });
		} else if (apiRequestReturn.response.status === 401) {
			void dispatch(navigate(ConfigCARSx.Pages[AppSection.LOGIN].route));
		} else if (apiRequestReturn.response.ok === true) {
			try {
				const helpers = (await apiRequestReturn.response.clone().json()) as HighwayHelperDto[];
				dispatch({ type: HHActionType.SET_HIGHWAY_HELPERS, helpers });
			} catch (error) {
				dispatch({ type: HHActionType.SET_ERROR_STATE, apiError: APIError.ResponseUnparseable });
			}
		} else {
			dispatch({ type: HHActionType.SET_ERROR_STATE, apiError: APIError.FetchFailed });
		}
		return apiRequestReturn;
	};

export const getHighwayHelperUsers =
	(): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		const url = new URL(ConfigHHApi.highwayHelperUsers, APIConfig.endpointURLBase);
		const apiRequestReturn = await APIRequest(
			new Request(url.href, {
				method: HTTPMethod.GET,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
			ConfigHHApi.endpointTimeoutMs,
		);

		try {
			if (apiRequestReturn.response?.ok === true) {
				const sspUsers = (await apiRequestReturn.response.clone().json()) as HighwayHelperUserDto[];
				dispatch({ type: HHActionType.SET_SSP_USERS, sspUsers });
			}
		} catch (error) {
			apiRequestReturn.apiError = APIError.ResponseUnparseable;
			if (DebuggingConfig.showConsoleLogs) {
				console.error(`Error fetching ssp users`, error);
			}
		}
		return apiRequestReturn;
	};

export const updateHighwayHelperUser =
	(sspUserBase: HighwayHelperUserDtoBase): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		const url = new URL(
			isValidNumber(sspUserBase.id)
				? ConfigHHApi.highwayHelperUserById(sspUserBase.id)
				: ConfigHHApi.highwayHelperUsers,
			APIConfig.endpointURLBase,
		);
		const apiRequestReturn = await APIRequest(
			new Request(url.href, {
				method: HTTPMethod.POST,
				headers: new Headers({
					...getAPIHeaders(),
				}),
				body: JSON.stringify(sspUserBase),
			}),
			ConfigHHApi.endpointTimeoutMs,
		);

		try {
			if (apiRequestReturn.response?.ok === true) {
				const sspUser = (await apiRequestReturn.response.clone().json()) as HighwayHelperUserDto;
				dispatch({ type: HHActionType.SET_SSP_USER, sspUser });
				dispatch(
					showMainBanner(NotificationErrorType.SUCCESS, {
						title: `Successfully ${
							isValidNumber(sspUserBase.id) ? 'updated' : 'added'
						} team member: ${sspUser.firstName} ${sspUser.lastName}`,
					}),
				);

				await dispatch(getHighwayHelperUsers());
			}
		} catch (error) {
			apiRequestReturn.apiError = APIError.ResponseUnparseable;
			if (DebuggingConfig.showConsoleLogs) {
				console.error(`Error updating ssp user`, error);
			}
		}
		return apiRequestReturn;
	};

export const deleteHighwayHelperUser =
	(id: number): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		const url = new URL(ConfigHHApi.highwayHelperUserById(id), APIConfig.endpointURLBase);
		const apiRequestReturn = await APIRequest(
			new Request(url.href, {
				method: HTTPMethod.DELETE,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
			ConfigHHApi.endpointTimeoutMs,
		);

		try {
			if (apiRequestReturn.response?.ok === true) {
				dispatch({ type: HHActionType.REMOVE_SSP_USER, id });
				dispatch(
					showMainBanner(NotificationErrorType.SUCCESS, {
						title: `Successfully deleted team member`,
					}),
				);
			}
		} catch (error) {
			apiRequestReturn.apiError = APIError.ResponseUnparseable;
			if (DebuggingConfig.showConsoleLogs) {
				console.error(`Error deleting ssp user`, error);
			}
		}
		return apiRequestReturn;
	};

let pollingTimeout: ReturnType<typeof setInterval>;

export const matchStringField = async (endpoint: string, fieldValue: string): Promise<boolean> => {
	if (fieldValue === null || fieldValue === undefined || fieldValue === '') {
		return false;
	}
	const url = new URL(endpoint, APIConfig.endpointURLBase);

	const apiRequestReturn = await APIRequest(
		new Request(url.href, {
			method: HTTPMethod.POST,
			body: JSON.stringify({ validateValue: fieldValue }),
			headers: new Headers({
				...getAPIHeaders(),
			}),
		}),
	);

	if (apiRequestReturn.response?.ok) {
		const status = (await apiRequestReturn.response?.json()) as boolean;

		return status;
	}

	return false;
};

export function matchMemberId(memberId: string): Promise<boolean> {
	return matchStringField(ConfigHHApi.getMatchUserMemberIdEndpoint, memberId);
}

export function matchEmail(email: string): Promise<boolean> {
	return matchStringField(ConfigHHApi.getMatchUserEmailEndpoint, email);
}

export function matchUserName(userName: string): Promise<boolean> {
	return matchStringField(ConfigHHApi.getMatchUserUserNameEndpoint, userName);
}

export const pollHH =
	(pollImmediately = true): ThunkActionRoot<Promise<void>> =>
	async (dispatch, getState): Promise<void> => {
		const { helpersLastPolled } = getState().hh;

		if (
			pollImmediately ||
			!helpersLastPolled ||
			Date.now() - helpersLastPolled > ConfigHHApi.pollingRate
		) {
			await dispatch(getHighwayHelpers());
		}
		clearInterval(pollingTimeout);
		pollingTimeout = setInterval(() => {
			void dispatch(getHighwayHelpers());
		}, ConfigHHApi.pollingRate);
	};

export const stopPollHH = (): ThunkActionRoot<void> => (): void => {
	clearInterval(pollingTimeout);
};

export const prepareDraftPatrolForServer = (draftPatrol: HighwayHelperDto): HighwayHelperDto => {
	const updatedDraftPatrol = {
		...draftPatrol,
		//	these are always defined by the server, the frontend can't change them
		created: undefined,
		updated: undefined,
		createdBy: undefined,
		updatedBy: undefined,
	};

	return updatedDraftPatrol;
};

export const updateHHPatrol =
	(draftPatrol: HighwayHelperDto): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		dispatch({
			type: HHActionType.UPDATE_HH_PATROL,
			draftPatrol,
		});
		dispatch(addItem('hh-form', 'await-update'));
		dispatch(setHHLoading(true));

		const preparedDraftPatrol = prepareDraftPatrolForServer(draftPatrol);

		const apiRequestReturn = await APIRequest(
			new Request(
				new URL(ConfigHHApi.HighwayHelperById(draftPatrol.id), APIConfig.endpointURLBase).href,
				{
					method: HTTPMethod.POST,
					headers: new Headers({
						...getAPIHeaders(),
					}),
					body: JSON.stringify(preparedDraftPatrol),
				},
			),
		);

		try {
			if (apiRequestReturn.response?.status === 400) {
				dispatch(
					showMainBanner(
						NotificationErrorType.ERROR,
						{ title: `Error updating patrol for: ${draftPatrol?.name ?? '<NO NAME>'}` },
						5000,
					),
				);
			}

			if (apiRequestReturn.response?.status === 500) {
				dispatch(
					showMainBanner(
						NotificationErrorType.ERROR,
						{ title: `Server error updating patrol for: ${draftPatrol?.name ?? '<NO NAME>'}` },
						5000,
					),
				);
			} else {
				const patrol = (await apiRequestReturn.response?.clone().json()) as HighwayHelperDto;
				dispatch(setHHDraftPatrol(patrol));
				dispatch({ type: HHActionType.HH_PATROL_UPDATED });
				dispatch(setHHUnsavedDraftPatrol(false));
				dispatch(
					showMainBanner(
						NotificationErrorType.SUCCESS,
						{ title: `Patrol for ${draftPatrol?.name ?? '<NO NAME>'} updated successfully` },
						5000,
					),
				);
				void dispatch(getHighwayHelpers());
			}
		} catch (error) {
			//	TODO: APIError value to handle this case
			dispatch(
				showMainBanner(
					NotificationErrorType.ERROR,
					{
						title: `Error updating patrol for: ${draftPatrol?.name ?? '<NO NAME>'}: ${
							error as string
						}`,
					},
					5000,
				),
			);
		}
		dispatch(setHHLoading(false));
		dispatch(removeItem('hh-form', 'await-update'));
		return apiRequestReturn;
	};

//	REDUCER

export const HHReducer = (
	state: HHState = HH_STATE_INITIAL,
	action: HHAction | undefined = undefined,
): HHState => {
	if (action === undefined) {
		return state;
	}
	switch (action.type) {
		case HHActionType.SET_ERROR_STATE:
			return {
				...state,
				apiError: action.apiError,
			};
		case HHActionType.SET_FIELDS:
			return {
				...state,
				apiError: undefined,
				fields: action.fields,
				fieldsLastPolled: Date.now(),
			};
		case HHActionType.SET_HIGHWAY_HELPERS:
			return {
				...state,
				apiError: undefined,
				helpers: action.helpers,
				helpersLastPolled: Date.now(),
			};
		case HHActionType.SET_HH_DRAFT_PATROL_PROP: {
			let { unsavedDraftPatrol, draftPatrol } = state;
			if (!draftPatrol) {
				//	can't set a prop for a patrol that doesn't exist
				return state;
			}
			const currentValue: unknown = get(draftPatrol, action.path);
			if (isEqual(currentValue, action.value) === false) {
				draftPatrol = set({ ...draftPatrol }, action.path, action.value);
				unsavedDraftPatrol = true;
			}
			return {
				...state,
				draftPatrol,
				unsavedDraftPatrol,
			};
		}

		case HHActionType.SET_HH_DRAFT_PATROL: {
			return {
				...state,
				draftPatrol: action.patrol,
			};
		}

		case HHActionType.SET_HH_UNSAVED_DRAFT_PATROL: {
			return {
				...state,
				unsavedDraftPatrol: action.isUnsaved,
			};
		}
		case HHActionType.SET_HH_TIMELINE:
			return {
				...state,
				helperTimeline: action.timeline,
			};
		case HHActionType.SET_HH_READ_ONLY_MODE:
			return {
				...state,
				readOnlyMode: action.readOnlyMode,
			};
		case HHActionType.SET_HH_LOADING:
			return {
				...state,
				loading: action.loading,
			};
		case HHActionType.SET_SSP_USER:
			return {
				...state,
				sspUsers: [
					...(state.sspUsers?.filter((user) => user.unitId !== action.sspUser.unitId) ?? []),
					action.sspUser,
				],
			};
		case HHActionType.REMOVE_SSP_USER:
			return {
				...state,
				sspUsers: [...(state.sspUsers?.filter((user) => user.id !== action.id) ?? [])],
			};
		case HHActionType.SET_SSP_USERS:
			return {
				...state,
				sspUsers: [...action.sspUsers],
			};
		default:
			return state;
	}
};
