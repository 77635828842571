import { css } from 'lit';
import get from 'lodash-es/get';
import set from 'lodash-es/set';
import isEqual from 'lodash-es/isEqual';
import structuredClone from '@ungap/structured-clone';
import { DebuggingConfig } from '@config/ConfigCARSx';
import { parse } from 'wellknown';
import { EventTypeConfig } from '@/config/ConfigREMEventTypes';
import { PreviewDto } from '../../../typings/api';
import { REMAction, REMActionType } from './rem-actions';
import { REMState, REM_STATE_INITIAL } from './rem-state';
import { EventAttribute } from '../../components/rem/rem-attributes';
import { DRAFT_PROP_PATHS_THAT_INVALIDATE_PRIORITY } from '../../config/ConfigREM';
import { determineEventPriority } from '../../utils/laneUtils';
import { selectDoesDraftEventHaveSufficientDataFor511Import } from './rem-selectors';

//	TODO: memoize?
export const areTimelinesEquivalent = (
	eventTimeline1: REMState['eventTimeline'],
	eventTimeline2: REMState['eventTimeline'],
): boolean =>
	eventTimeline1?.id === eventTimeline2?.id &&
	//	easiest way to tell whether they're different is if their latest entry has a different timestamp
	eventTimeline1?.timeline?.entries?.[0].timestamp ===
		eventTimeline2?.timeline?.entries?.[0].timestamp;

export const unsaveableDraftPropPaths = [
	'nearbyCameras',
	'locationDetails',
	'locationDetails.county',
	'locationDetails.district',
	'locationDetails.subdistrict',
	'locationDetails.unit',
];

export const doAttributesDiffer = (
	attributes: EventAttribute[],
	otherAttributes: EventAttribute[],
): boolean => {
	const sortedA = [...attributes].sort();
	const sortedB = [...otherAttributes].sort();

	if (sortedA.length !== sortedB.length) return true;
	return sortedA.some((attributeA, index) => attributeA.value !== sortedB[index].value);
};

export const REMReducer = (
	state: REMState = REM_STATE_INITIAL,
	action: REMAction | undefined = undefined,
): REMState => {
	if (action === undefined) {
		return state;
	}
	switch (action.type) {
		case REMActionType.SET_REM_EVENTS:
			return {
				...state,
				events: action.events,
				eventsLastPolled: Date.now(),
			};
		case REMActionType.SET_REM_DRAFT_EVENT: {
			const { draftEvent } = action;
			const eventType = draftEvent?.eventType;

			return {
				...state,
				draftEvent,
				initialDraftEvent: structuredClone(draftEvent),
				unsavedDraftEvent: false,
				mileMarkersValidForRoute: true,
				shouldRefreshEventMessages:
					draftEvent === undefined ? false : state.shouldRefreshEventMessages,
				draftEventGeometryString:
					(draftEvent?.geometry && parse(draftEvent?.geometry)) || undefined,
				userHasModifiedIncludeInEventFeed:
					eventType !== undefined && selectDoesDraftEventHaveSufficientDataFor511Import(draftEvent)
						? draftEvent?.includeInEventFeed !== EventTypeConfig[eventType]?.importTo511
						: false,
			};
		}
		case REMActionType.SET_REM_EVENT_FIELDS:
			return {
				...state,
				eventFields: action.eventFields,
			};
		case REMActionType.SET_REM_EVENT_EMAIL_RECIPIENTS: {
			const emailGroupsRecipients = { ...state.draftEvent?.emailGroupsRecipients };
			const emailGroups = [...(emailGroupsRecipients?.emailGroups ?? [])];

			if (emailGroups) {
				emailGroups.forEach((draftEmailGroup, index) => {
					const matchedGroup = action.emailGroupsRecipients?.emailGroups?.find(
						(savedEmailGroup) => savedEmailGroup.id === draftEmailGroup.id,
					);

					if (matchedGroup) {
						emailGroups[index] = matchedGroup;
					}
				});
			}

			return {
				...state,
				draftEvent: {
					...state.draftEvent,
					emailGroupsRecipients: { ...emailGroupsRecipients, emailGroups },
				},
				emailGroupsRecipients: action.emailGroupsRecipients,
			};
		}
		case REMActionType.DELETE_REM_EVENT_EMAIL_GROUPS: {
			const newRecipients = { ...state.emailGroupsRecipients };
			newRecipients.emailGroups = newRecipients.emailGroups?.filter(
				(group) => group.id !== undefined && !action.groupIds.includes(group.id),
			);
			return {
				...state,
				emailGroupsRecipients: newRecipients,
			};
		}
		// TODO: this draft event prop system is a mess
		// would be a pretty big refactor but should try and bring in cra-form-controller
		case REMActionType.SET_REM_DRAFT_EVENT_PROP: {
			const { shouldRefreshEventMessages } = state;
			let { unsavedDraftEvent, draftEvent } = state;
			let unsavedEventProps = [...(state.unsavedEventProps ?? [])];
			const { initialDraftEvent } = state;
			const initialValue: unknown = get(initialDraftEvent, action.path);
			const currentValue: unknown = get(draftEvent, action.path);

			const valuesAreNullStrings =
				(initialValue === null || initialValue === undefined || initialValue === '') &&
				(action.value === null || action.value === undefined || action.value === '');

			// internal attribute objects just have a string value prop, but attributes in the event
			// ...are objects with more props, we only care about changes to values
			const valueDiffersFromInitial =
				(action.path !== 'attributes' && isEqual(initialValue, action.value) === false) ||
				(action.path === 'attributes' &&
					doAttributesDiffer(
						action.value as EventAttribute[],
						(initialValue ?? []) as EventAttribute[],
					));

			if (isEqual(currentValue, action.value) === false) {
				draftEvent = set({ ...draftEvent }, action.path, action.value);
			}

			if (DRAFT_PROP_PATHS_THAT_INVALIDATE_PRIORITY.includes(action.path) && draftEvent) {
				console.info(`priority refreshed due to: `, action.path);
				draftEvent.priorityLevel = determineEventPriority(state, draftEvent);
			}

			//	these props set as a consqeuence of initial location details from existing events
			const shouldIgnoreChange =
				state.unsavedDraftEvent === false &&
				(action.path === 'signIds' ||
					action.path === 'selectedSigns' ||
					action.path === 'nearbyCameras' ||
					action.path === 'locationDetails');

			if (valueDiffersFromInitial && !valuesAreNullStrings && !shouldIgnoreChange) {
				unsavedDraftEvent = true;
				unsavedEventProps.push(action.path);
			} else if (!shouldRefreshEventMessages) {
				unsavedEventProps = unsavedEventProps.filter((s) => s !== action.path);

				if (!unsavedEventProps || !unsavedEventProps.length) {
					unsavedDraftEvent = false;
				}
			}
			return {
				...state,
				draftEvent,
				unsavedDraftEvent,
				unsavedEventProps,
			};
		}
		case REMActionType.REM_EVENT_UPDATED:
			return {
				...state,
				unsavedDraftEvent: false,
			};
		case REMActionType.SET_REM_EVENT_TIMELINE:
			if (areTimelinesEquivalent(state.eventTimeline, action.eventTimeline)) {
				return {
					...state,
					currentDetailsId: action.eventTimeline?.currentDetailsId,
				};
			}
			return {
				...state,
				eventTimeline: action.eventTimeline,
				currentDetailsId: action.eventTimeline?.currentDetailsId,
			};
		case REMActionType.SHOW_REM_EVENT_SECTION:
			return {
				...state,
				formSections: {
					...state.formSections,
					[action.section]: true,
				},
			};
		case REMActionType.HIDE_REM_EVENT_SECTION:
			return {
				...state,
				formSections: {
					...state.formSections,
					[action.section]: false,
				},
			};
		case REMActionType.SET_ROUTES_BY_LOCATION_META:
			return {
				...state,
				filteredRoutes: action.filteredRoutes,
			};
		case REMActionType.SET_ROUTES_FILTER_PARAM:
			return {
				...state,
				routeFilterParams: {
					...(state.routeFilterParams ?? { county: undefined, district: undefined }),
					[action.path]: action.value,
				},
			};
		case REMActionType.RESET_LOCATION_DETAILS:
			return {
				...state,
				draftEvent: {
					...state.draftEvent,
					//	wipe location data
					route: undefined,
					startMileMarker: undefined,
					endMileMarker: undefined,
					locationDetails: undefined,
					negativeLaneBlockage: undefined,
					negativeLaneBlockageType: undefined,
					positiveLaneBlockage: undefined,
					positiveLaneBlockageType: undefined,
					//	probably unnecessary
					lat: undefined,
					lon: undefined,
					nearbyCameras: undefined,
				},
				eventDMSPreviewMessages: undefined, //	DMS previews are invalidated if the location changes
				draftEventGeometryString: undefined,
				draftEventRouteGeometry: undefined,
				draftEventIcon: undefined,
			};
		case REMActionType.SET_DMS_PREVIEW:
			if (action.eventDMSPreviewMessages === undefined) {
				const newState = { ...state };
				delete newState.eventDMSPreviewMessages;
				return newState;
			}
			return {
				...state,
				eventDMSPreviewMessages: { ...action.eventDMSPreviewMessages },
			};
		case REMActionType.SET_DMS_PREVIEW_LOADING_IDS:
			return {
				...state,
				eventDMSSignLoadingIds: [...action.eventDMSSignLoadingIds],
				eventDMSPreviewMessages: action.eventDMSPreviewMessages
					? { ...action.eventDMSPreviewMessages }
					: state.eventDMSPreviewMessages,
				unsavedDraftEvent: action.unsavedDraftEvent ?? state.unsavedDraftEvent,
			};
		case REMActionType.REMOVE_DMS_FROM_REM_EVENT: {
			const eventDMSPreviewMessages = { ...state.eventDMSPreviewMessages } as Record<
				number,
				PreviewDto[] | null
			>;
			delete eventDMSPreviewMessages[action.signId];
			return {
				...state,
				eventDMSPreviewMessages,
				eventDMSSignLoadingIds: state.eventDMSSignLoadingIds?.filter(
					(signId) => signId !== action.signId,
				),
				unsavedDraftEvent: true,
			};
		}
		case REMActionType.REM_SET_SIGN:
			return { ...state, currentSign: action.currentSign };
		case REMActionType.SET_SIGN_QUEUE:
			return { ...state, currentSignQueue: action.currentSignQueue };
		case REMActionType.SET_REM_READ_ONLY_MODE:
			return {
				...state,
				readOnlyMode: action.readOnlyMode,
			};
		case REMActionType.SET_REM_EVENT_CURRENT_EDITORS:
			return {
				...state,
				currentEditors: action.currentEditors,
				currentDetailsId: action.currentEditors?.currentDetailsId ?? state.currentDetailsId,
			};
		case REMActionType.SET_MILE_MARKERS_VALID_FOR_ROUTE:
			return {
				...state,
				mileMarkersValidForRoute: action.mileMarkersValidForRoute,
			};
		case REMActionType.SET_REM_LOADING:
			return {
				...state,
				loading: action.loading,
			};
		case REMActionType.SET_DISPOSITION_ERROR:
			return {
				...state,
				dispositionError: action.dispositionError ?? false,
			};
		case REMActionType.PRIORITIZE_MESSAGES:
			return {
				...state,
				shouldRefreshEventMessages: true,
				unsavedDraftEvent: true,
			};
		case REMActionType.SET_REM_DRAFT_EVENT_ROUTE_EXTENT:
			return {
				...state,
				draftEventGeometryString: action.geometry,
			};
		case REMActionType.SET_REM_DRAFT_EVENT_DESCRIPTION_PREVIEW:
			return {
				...state,
				draftEventDescription: action.description,
			};
		case REMActionType.SET_REM_NAMED_POINTS_FOR_ROUTE: {
			const namedPoints = { ...state.namedPoints };
			namedPoints[action.route] = action.namedPoints;

			return {
				...state,
				namedPoints,
			};
		}
		case REMActionType.SET_REM_AREAS:
			return {
				...state,
				cities: action.areas,
			};
		case REMActionType.SET_REM_DRAFT_EVENT_ICON:
			return {
				...state,
				draftEventIcon: action.icon,
			};
		case REMActionType.SET_REM_DRAFT_EVENT_FUTURE_ICON:
			return {
				...state,
				draftEventFutureIcon: action.futureIcon,
			};
		case REMActionType.ADD_REM_EVENT_ICON_TO_RECORD: {
			const eventIcons = { ...state.eventIcons };
			eventIcons[action.eventId] = action.icon ?? '';

			return {
				...state,
				eventIcons,
			};
		}
		case REMActionType.SET_REM_DRAFT_EVENT_ROUTE_GEOMETRY:
			return {
				...state,
				draftEventRouteGeometry: action.geometry,
			};
		case REMActionType.SET_REM_ROUTES_WITH_RAMPS:
			return {
				...state,
				routesWithRamps: action.routes,
			};
		case REMActionType.ADD_RAMPS_TO_RECORD: {
			const ramps = { ...state.ramps };
			ramps[action.route] = action.ramps;

			return {
				...state,
				ramps,
			};
		}
		case REMActionType.SET_REM_USER_HAS_MODIFIED_INCLUDE_IN_EVENT_FEED:
			return {
				...state,
				userHasModifiedIncludeInEventFeed: action.modified,
			};
		//	intentional fallthrough for strong independant actions that don't need no reducer
		case REMActionType.ADD_NOTE_TO_EVENT_TIMELINE:
		case REMActionType.CREATE_REM_EVENT:
		case REMActionType.GET_DMS_FOR_REM:
		case REMActionType.GET_LOCATION_DETAILS:
		case REMActionType.GET_REM_EVENT_EMAIL_RECIPIENTS:
		case REMActionType.GET_REM_EVENT_FIELDS:
		case REMActionType.GET_REM_EVENT_FROM_EVENTS:
		case REMActionType.GET_REM_EVENT_TIMELINE:
		case REMActionType.GET_REM_EVENT:
		case REMActionType.GET_REM_EVENTS:
		case REMActionType.GET_REM_NEARBY_CAMERAS:
		case REMActionType.GET_ROUTES_BY_LOCATION_META:
		case REMActionType.GET_SIGN_QUEUE:
		case REMActionType.GET_SIGN:
		case REMActionType.UPDATE_REM_EVENT:
		case REMActionType.GET_REM_EVENT_CURRENT_EDITORS:
		case REMActionType.GET_REM_DRAFT_EVENT_ROUTE_EXTENT:
		case REMActionType.GET_REM_DRAFT_EVENT_DESCRIPTION_PREVIEW:
		case REMActionType.GET_REM_DRAFT_EVENT_ICON:
			return state;
		default:
			if (Object.values(REMActionType).includes(action.type)) {
				if (DebuggingConfig.showConsoleLogs) {
					console.warn('redux action unhandled in reducer:', action);
				}
			}
			return state;
	}
};
export default REMReducer;
