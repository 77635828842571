import { css } from 'lit';
import { HH_COLUMNS, MILLISECONDS_PER_SECOND } from '../constants';

interface ConfigHH {
	Table: {
		Pagination: number[];
	};
}

export const ConfigHH: ConfigHH = {
	Table: {
		Pagination: [5, 15, 50, 100],
	},
};

export enum PatrolActions {
	END = 'end',
	START = 'start',
	EDIT = 'edit',
}

export const ConfigHHApi = {
	getMatchUserMemberIdEndpoint: `highway-helper/validate/memberId`,
	getMatchUserEmailEndpoint: `highway-helper/validate/email`,
	getMatchUserUserNameEndpoint: `highway-helper/validate/userName`,
	Endpoint: 'highway-helper',
	FieldsEndpoint: 'highway-helper/fields',
	HighwayHelperById: (hhid: number): string => `highway-helper/${hhid}`,
	TimelineById: (hhid: number): string => `highway-helper/${hhid}/timeline`,
	highwayHelperUsers: 'highway-helper/user',
	highwayHelperUserById: (id: number) => `highway-helper/user/${id}`,
	pollingRate: 60 * MILLISECONDS_PER_SECOND,
	endpointTimeoutMs: 60 * MILLISECONDS_PER_SECOND,
	dateFormat: 'YYYY-MM-DDTHH:mm:ss.SSSSZ',
	dateOptions: {
		year: 'numeric',
		month: 'numeric',
		day: 'numeric',
		hour: '2-digit',
		minute: '2-digit',
	},
};

export const ConfigHHTable = {
	pagination: [5, 15, 50, 100],
	defaultPagination: 3,
	pollingDelayMs: 10 * MILLISECONDS_PER_SECOND,
	defaultColumns: [
		'name',
		'status',
		'disposition',
		'commission',
		'zone',
		'GPS',
		'REM',
		'dashcam',
		'ptzcam',
		'edit',
		'patrol',
	] as typeof HH_COLUMNS[number][],
};

export const SSP_ADMIN_COLUMNS = <const>['unitID', 'name', 'email', 'userName'];

export const ConfigSSPAdminTable = {
	pagination: [5, 15, 50, 100],
	defaultPagination: 1,
	pollingDelayMs: 10 * MILLISECONDS_PER_SECOND,
	defaultColumns: ['unitID', 'name', 'email', 'userName'] as typeof SSP_ADMIN_COLUMNS[number][],
};
